import { environment } from '@environments/environment'
import { IAuthUserInfo } from '@lib/interfaces/auth-user.interface'
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals'

type AppState = {
	isUserSignedIn: boolean
	userRole: string
	appVersion: string
	isInDevelopment: boolean
	isInProduction: boolean
	userInfo: Partial<IAuthUserInfo> | null
}

const initialState: AppState = {
	isUserSignedIn: false,
	userRole: 'guest',
	appVersion: 'V1.2.9',
	userInfo: null,
	isInDevelopment: !environment.production,
	isInProduction: environment.production,
}

export const AppStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withMethods((store) => ({
		setUserSession(user: Partial<IAuthUserInfo>): void {
			patchState(store, {
				userRole: user['role'] as string,
				userInfo: { ...store.userInfo(), ...user },
			})
		},

		setUserSignedIn(signedIn: boolean) {
			patchState(store, {
				isUserSignedIn: signedIn,
			})
		},

		signOut(): void {
			patchState(store, { isUserSignedIn: false, userRole: 'guest', userInfo: null })
		},
	}))
)
